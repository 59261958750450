<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.versionNo' placeholder="输入版本号" clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="APP类型:">
                        <el-select v-model.number="filters.appType" clearable @clear='clearContent'>
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        :data="listData"
        highlight-current-row
        v-loading="listLoading"
        border=""
        @current-change="selectCurrentRow"
        style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="Apptype" align="center" label="APP类型" min-width="100">
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.AppType === 0" type='primary'>{{'Android'}}</el-tag>
                    <el-tag v-else type='warning'>{{'IOS'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="VersionInfo" align="center" label="版本号" min-width="100"></el-table-column>
            <el-table-column prop="DownloadUrl" align="center" label="下载地址" min-width="100" show-overflow-tooltip></el-table-column>
            <el-table-column prop="IsNew" align="center" label="是否最新" min-width="100">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsNew == true ? "success" : "warning"'>{{scope.row.IsNew == true ? '是' : '否'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" align="center" label="启用标识" min-width="100">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable == true ? "primary" : "warning"'>{{scope.row.IsEnable == true ? '启用' : '禁用'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CreateUserName" align="center" label="创建人名称" min-width="110"></el-table-column>
            <el-table-column prop="CreateTime" align="center" label="创建时间" min-width="100" :formatter="formatCreateTime"></el-table-column>
            <el-table-column prop="UpdateContent" align="center" label="更新描述" min-width="100" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal='false'
        @close='dialogClose'
        width="45%">
        <el-form :model="Form" :rules="rules" ref="FormRef" label-width="150px">
            <el-form-item label="APP类型：" prop="AppType">
                <el-select v-model="Form.AppType" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="版本号：" prop="VersionInfo">
                <el-input v-model="Form.VersionInfo"></el-input>
            </el-form-item>
            <el-form-item label="下载地址：" prop="DownloadUrl" v-if='isAdd == false'>
                <el-input v-model="Form.DownloadUrl" disabled></el-input>
            </el-form-item>
            <el-form-item label='上传安装包：' class='FileClass' v-if='isAdd'>
                <template>
                    <input type="file" id='file' name='file' accept='#' required='required' ref='file' mustwrite="true">
                </template>
            </el-form-item>
            <el-form-item label="启用标识：" prop='IsEnable'>
                <el-select v-model="Form.IsEnable" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="更新描述：" prop='UpdateContent'>
                <el-input v-model="Form.UpdateContent" type='textarea'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit" :loading="submitLoading">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import api from '@/api'
import Qs from 'qs'
import util from "../../../util/date";
import {
	getAPPVersionInfoListPage,
	updateAPPVersionInfo,
	deleteAPPVersionInfo,
	addAPPVersionInfoLibrary1,
	uploadTempFile
} from '../../api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                versionNo:'',
                appType:''
            },
            options:[
                {value:0,label:'Android'},
                {value:1,label:'IOS'}
            ],
            options1:[
                {value:true,label:'启用'},
                {value:false,label:'禁用'}
            ],
            listLoading:false,
            submitLoading:false,
            listData:[],
            currentRow:{},
            pages:{
                pageSize:20,
                pageIndex:1,
                dataCount:0
            },
            dialogTitle:'新增',
            dialogVisible:false,
            isAdd:true,
            Form:{
                APPType:'',
                VersionNo:'',
                DownloadUrl:'',
                IsEnable:true,
                Remarks:''
            },
            rules:{
                APPType:[{required:true,message:'请选择APP类型',trigger:'blur'}],
                VersionNo:[{required:true,message:'请输入版本号',trigger:'blur'}],
                DownloadUrl:[],
                Remarks:[{trigger:'blur'}]
            },
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd  hh:mm:ss");
        },
        // 清空筛选项，重获数据
        clearContent(){
            this.getData()
        },
        // 清空对话框
        dialogClose(){
            if(this.isAdd){
                var formData = new FormData()
                var fileValue = document.getElementById('file')
                fileValue.value = ''
                formData.set('file',null)
                this.$refs.FormRef.resetFields()
            }else{
                this.$refs.FormRef.resetFields()
            }
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = JSON.parse(JSON.stringify(row))
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
	            searchKey:this.filters.versionNo,
                appType:this.filters.appType,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            getAPPVersionInfoListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.listData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        // 查询
        getAPPVersionInfoListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.isAdd = true
            this.dialogTitle = '新增'
            this.dialogVisible = true
            this.Form.IsEnable = true
        },
        // 编辑
        handleEdit(){
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的一行数据！')
            }else{
                this.isAdd = false
                this.dialogTitle = '编辑'
                this.dialogVisible = true
                this.Form = {...row}
                this.Form.APPType = row.Apptype
            }
        },
        // 删除
        handleDel(){
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据！')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteAPPVersionInfo({Id:row.Id}).then(res => {
                        if(res.data.Success){
                            this.getData()
                            this.$message.success(res.data.Message)
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    })
                }).catch(() => {})
            }
        },
        // 新增/编辑表单提交
        submit(){
            if(this.isAdd){
                var fileValue = document.getElementById('file').value
                this.$refs['FormRef'].validate(valid => {
                    if(valid){
                        if(this.Form.APPType == 0 && fileValue.length == 0){
                            this.$message.warning('请上传文件')
                        }else{
                            var formData = new FormData()
                            var  file = document.getElementById('file')
                            formData.append('file',file.files[0])
                            formData.append('AppType',this.Form.AppType)
                            formData.append('VersionInfo',this.Form.VersionInfo)
                            formData.append('DownloadUrl',this.Form.DownloadUrl)
                            formData.append('IsEnable',this.Form.IsEnable)
                            formData.append('UpdateContent',this.Form.UpdateContent)
                            this.submitLoading = true
                            axios({
                                method: 'post',
                                headers:{
                                    'Content-Type':'multipart/form-data',
                                    'Authorization':localStorage.getItem("Token")
                                },
                                url: `${addAPPVersionInfoLibrary1}`,
                                // url: `${uploadTempFile}`,
                                data: formData
                            }).then(res => {
                                if(res.data.Success){
                                    this.$message.success(res.data.Message)
                                    this.dialogVisible = false
                                    this.submitLoading = false
                                    this.dialogClose()
                                    this.getData()
                                    this.Form.IsEnable = true
                                }else{
                                    this.$message.error(res.data.Message)
                                    this.Form = {}
                                    this.submitLoading = false
                                    this.dialogClose()
                                    this.Form.IsEnable = true
                                }
                            }).catch(() => {
                                    this.Form = {}
                                    this.submitLoading = false
                                    this.dialogClose()
                                    this.Form.IsEnable = true
                            })
                        }
                    }else{return false}
                })
            }else{
                this.$refs['FormRef'].validate(valid => {
                    if(valid){
                        this.submitLoading = true
                        var params = {...this.Form}
                        updateAPPVersionInfo(params).then(res => {
                            if(res.data.Success){
                                this.$message.success(res.data.Message)
                                this.submitLoading = false
                                this.dialogVisible = false
                                this.dialogClose()
                                this.Form = {}
                                this.getData()
                            }else{
                                this.$message.success(res.data.Message)
                                this.submitLoading = false
                                this.dialogVisible = false
                                this.dialogClose()
                            }
                        }).catch(() => {})
                    }else{return}
                })
            }                     
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
    },
    mounted(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .FileClass::before{
        position: relative;
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
        float: left;
        margin-left: -10px;
        left: 56px;
        top: 10px;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>